import { createRouter, createWebHistory } from '@ionic/vue-router';
import { checkIfUser, getTypeUser, routeTypeUser } from '@/services/users';

/**
 * Checking that a user is logged in
 * Used in login page
 * If user is already logged-in, redirect him to planning
 */
const ensureNoUser = function () {
  if (checkIfUser()) {
    return routeTypeUser();
  }

  return true;
};

/**
 * Verification that the user is a driver
 */
const ensureDriver = function () {
  if (getTypeUser() === 'driver') {
    return true;
  }

  return routeTypeUser();
};

const routes = [
  {
    path: '/',
    redirect: '/connexion',
  },

  // Application login page
  {
    path: '/connexion',
    name: 'Connexion',
    component: () => import('@/views/Login.vue'),
    beforeEnter: ensureNoUser,
  },

  // Error page
  {
    path: '/:pathMatch(.*)',
    name: 'NotFound',
    component: () => import('@/views/NotFound.vue'),
    beforeEnter: ensureDriver,
  },

  {
    path: '/documents',
    name: 'Documents',
    component: () => import('@/views/driver/Documents.vue'),
    beforeEnter: ensureDriver,
  },

  // The three main routes
  {
    path: '/planning',
    name: 'PlanningDriver',
    component: () => import('@/views/driver/Planning.vue'),
    beforeEnter: ensureDriver,
  },
  {
    path: '/settings',
    name: 'SettingsDriver',
    component: () => import('@/views/driver/Settings.vue'),
    beforeEnter: ensureDriver,
  },

  {
    path: '/time-reports',
    name: 'TimeReport',
    component: () => import('@/views/driver/timeReport/ListTimeReport.vue'),
    beforeEnter: ensureDriver,
  },
  {
    path: '/time-reports/:date',
    name: 'UpdateTimeReport',
    component: () => import('@/views/driver/timeReport/TimeReportUpdate.vue'),
    beforeEnter: ensureDriver,
  },

  {
    path: '/listeBonLocation',
    name: 'ListRentalCoupon',
    component: () => import('@/views/driver/AllRentalCoupon.vue'),
    beforeEnter: ensureDriver,
  },
  {
    path: '/chantier/:id/:idRide',
    name: 'WorksiteDetail',
    component: () => import('@/views/driver/missionOrder/WorksiteDetail.vue'),
    beforeEnter: ensureDriver,
  },
  {
    path: '/transfert/:id/:idRide/:idItem',
    name: 'TransferDetail',
    component: () => import('@/views/driver/missionOrder/TransferDetail.vue'),
    beforeEnter: ensureDriver,
  },
  {
    path: '/livraison/:id/:idRide/:idItem',
    name: 'DeliveryDetail',
    component: () => import('@/views/driver/missionOrder/DeliveryDetail.vue'),
    beforeEnter: ensureDriver,
  },
  {
    path: '/chantier/:id/:idRide/bonLocation/create',
    name: 'CreateRentalCouponWorksite',
    component: () =>
      import('@/views/driver/rentalCoupon/RentalWorksiteCreate.vue'),
    beforeEnter: ensureDriver,
  },
  {
    path: '/transfert/:id/:idRide/:idItem/bonLocation/create',
    name: 'CreateRentalCouponTransfer',
    component: () =>
      import('@/views/driver/rentalCoupon/RentalTransferCreate.vue'),
    beforeEnter: ensureDriver,
  },
  {
    path: '/livraison/:id/:idRide/:idItem/bonLocation/create',
    name: 'CreateRentalCouponDelivery',
    component: () =>
      import('@/views/driver/rentalCoupon/RentalDeliveryCreate.vue'),
    beforeEnter: ensureDriver,
  },
  {
    path: '/chantier/:id/:idRide/bonLocation/:idRentalCoupon',
    name: 'UpdateRentalCouponWorksite',
    component: () =>
      import('@/views/driver/rentalCoupon/RentalWorksiteUpdate.vue'),
    beforeEnter: ensureDriver,
  },

  {
    path: '/transfert/:id/:idRide/:idItem/bonLocation/:idRentalCoupon',
    name: 'UpdateRentalCouponTransfer',
    component: () =>
      import('@/views/driver/rentalCoupon/RentalTransferUpdate.vue'),
    beforeEnter: ensureDriver,
  },
  {
    path: '/livraison/:id/:idRide/:idItem/bonLocation/:idRentalCoupon',
    name: 'UpdateRentalCouponDelivery',
    component: () =>
      import('@/views/driver/rentalCoupon/RentalDeliveryUpdate.vue'),
    beforeEnter: ensureDriver,
  },

  {
    path: '/aide',
    name: 'Help',
    component: () => import('@/views/driver/Help.vue'),
    beforeEnter: ensureDriver,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
